import decode from 'jwt-decode'
import router from '../router/router'

import { make } from 'vuex-pathify'

import access from '../layouts/full-layout/vertical-sidebar/VerticalSidebarItems'
// import axios from 'axios'

// action types
export const SAVETOKEN = "SAVETOKEN";
export const AUTOLOGIN = "AUTOLOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const UPDATE = "UPDATE";
export const FETCH = "FETCH";
export const SAVESOCKETID = "SAVESOCKETID";
export const UPDATE_PASSWORD = "updateUser";
export const LOADING = "LOADING";

// mutation types
export const SETTOKEN = "SETTOKEN";
export const SETUSER = "SETUSER";
export const SETMODULES = "SETMODULES";
export const SETLOGO = "SETLOGO";

const state = {
  socket_id: null,
  Sidebar_drawer: null,
  Customizer_drawer: false,
  Sidebarcolor: "#1E1E1E", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
  Sidebarbg: "",
  navbarcolor: "#1E1E1E",
  horizontallayout: false, // Horizontal layout
  token: localStorage.getItem('token-compamy-ai'),
  user: JSON.parse(localStorage.getItem('compamy-ai-user')),
  modules: JSON.parse(localStorage.getItem('compamy-ai-modules')) || [],  
  loading: false,
  logo: '',
  user_info: JSON.parse(localStorage.getItem('userInfo-company-ai')) || {}
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  enterpriseUserInfo(state){
    if(state.user.enterpriseUser) return state.user_info || {};
    return false;
  }
};

const mutations = make.mutations(state);

// const mutations = {
//   SET_SIDEBAR_DRAWER(state, payload) {
//     state.Sidebar_drawer = payload;
//   },
//   SET_CUSTOMIZER_DRAWER(state, payload) {
//     state.Customizer_drawer = payload;
//   },
//   SET_SIDEBAR_COLOR(state, payload) {
//     state.Sidebarcolor = payload;
//   },
//   SET_NAVBAR_COLOR(state, payload) {
//     state.navbarcolor = payload;
//   },
//   SET_LAYOUT(state, payload) {
//     state.horizontallayout = payload;
//   },
//   [SETTOKEN](state, token){
//     state.token = token
//   },
//   [SETUSER](state, user){
//     state.user = user
//     localStorage.setItem('compamy-ai-user', JSON.stringify(user))
//     
//   },
// };

const actions = {
  [LOADING]: ({commit}, value) => {
    commit('SET_LOADING', value)
  },
  [SAVETOKEN]({commit, state}, {token, company_logo, userInfo}){
    const user = decode(token)

    commit('SET_TOKEN', token)
    commit('SET_USER', user)
    commit('SET_LOGO', company_logo)
    commit('SET_USER_INFO', userInfo)

    if(user.role == 'Staff' || user.role == 'Assitant'){
      router.push({name: 'Chat'})
    }else{
      router.push({name: 'Analytical'})
    }
    
    localStorage.setItem('token-company-ai', token)
    localStorage.setItem('userInfo-company-ai', JSON.stringify(userInfo))
    
    // set modules
    state.modules = [];
    if(state.user) state.modules = access[user.role]() || [];
    localStorage.setItem('compamy-ai-modules', JSON.stringify(state.modules))
  },
  [SAVESOCKETID]({commit}, socketId){
    commit('SET_SOCKET_ID', socketId)
  },
  [AUTOLOGIN]({commit}){      
    const token = localStorage.getItem('token-compamy-ai');
    const user = decode(token)
    if(!token) return
    commit('SETTOKEN', token)
    commit('SETUSER', user) 
  },
  [LOGOUT]({commit}){
    // commit('SET_TOKEN', null)
    // commit('SET_USER', null)
    // commit('SET_MODULES', null)
    const local = localStorage.getItem('compamy-ai@state') || '{}'
    const user = JSON.parse(local)    
    
    for (let key in user) {      
      commit(`SET_${key.toUpperCase()}`, null)
    }
    localStorage.removeItem('token-company-ai')
    localStorage.removeItem('compamy-ai-user')
    localStorage.removeItem('compamy-ai-modules')
    localStorage.removeItem('compamy-ai@state')
    localStorage.removeItem('userInfo-company-ai')
    router.replace({name: 'SignIn'}).catch(()=>{});
  },
  [UPDATE]: ({ state }) => {    
    localStorage.setItem('compamy-ai@state', JSON.stringify(state))
  },
  [FETCH]: ({ commit }) => {
    const local = localStorage.getItem('compamy-ai@state') || '{}'
    const state = JSON.parse(local)    
    
    for (let key in state) {   
      if(key == 'modules') return 
      commit(`SET_${key.toUpperCase()}`, state[key])
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
